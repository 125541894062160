import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../Axios/AxiosInstance";
import { config } from "@fortawesome/fontawesome-svg-core";
import toast from "react-hot-toast";

const Privacy = () => {
  const [text, setText] = useState("");

  // Fetch the existing FAQ content on component mount
  useEffect(() => {
    const fetchFAQ = async () => {
      try {
        const response = await axiosInstance.get('/faq', config);
        // console.log(response?.data?.data?.[0].details|| "")
        setText(response?.data?.data?.[0].details || "");
      } catch (error) {
        console.error("Failed to fetch FAQ content.");
      }
    };
    fetchFAQ();
  }, []);
  useEffect(() => {
    document.title = "Privacy | CAJobPortal";
  }, []);
  return (<>
   {text ? <>
    <div>
      <div className="milestone p-4 mb-2">
        {/* <h2 class="text-center">Privacy Policy</h2> */}
      </div>
      <div
        style={{ marginLeft: "100px", marginRight: "100px", fontSize: "12px" }}
      >
        <p  dangerouslySetInnerHTML={{ __html:text}}></p>
      </div>
    </div>
   </> : <>
   <div>
      <div className="milestone p-4 mb-2">
        <h2 className="text-center">Privacy Policy</h2>
      </div>
      <div
        style={{ marginLeft: "100px", marginRight: "100px", fontSize: "12px" }}
      >
        <h4>Privacy Policy</h4>
        <br />
        <br />
        <b>1. Introduction </b>
        <br />
        <br />
        We, at CAJobPortal.com are committed to respecting your online privacy
        and recognize the need for appropriate protection and management of any
        personally identifiable information you share with us. This Privacy
        Policy ("<b>Policy</b>") describes how CAJobPortal.com collects, uses,
        discloses and transfers personal information of users through its
        websites and applications.This policy applies to those who visit the
        Platform, or whose information CAJobPortal.com otherwise receives in
        connection with its services
        <br />
        <br />
        We have implemented reasonable security practices and procedures that
        are commensurate with the information assets being protected and with
        the nature of our business. While we try our best to provide security
        that is better than the industry standards, because of the inherent
        vulnerabilities of the internet, we cannot ensure or warrant complete
        security of all information that is being transmitted to us by you. By
        visiting this Website, you agree and acknowledge to be bound by this
        Privacy Policy and you hereby consent that we will collect, use, process
        and share your Personal Information in the manner set out herein below.
        If you do not agree with these terms, do not use the Website.
        <br />
        <div>
          <br />
          <b>Account information</b>
          <br />
          <br />
          If you create an account to take advantage of the full range of
          services offered on CAJObPortal.com, we ask for and record Personal
          Information such as your name, email address and mobile number. We may
          collect and store your Sensitive Personal Data or Information (such as
          any financial information including inter alia credit card, debit card
          details, bank account and know your customer (" <b>KYC</b>") documents
          as per RBI regulations and any other information as may be applicable)
          that the User may opt to save in the User account created with
          CAJObPortal.com]. We use your email address to send you updates, news,
          and newsletters (if you willingly subscribe to the newsletter during
          signup, or anytime after signup) and contact you on behalf of other
          Users (such other Users who send you friend requests, personal
          messages, or other social collaboration based events). [If you do not
          want to receive communications from us that are not relevant to you or
          your use of our services, please click on the unsubscribe link
          provided at the bottom of such e-mails sent to you by us. We use your
          mobile numbers to send you transaction alerts and SMS alerts based on
          your preferences. If you do not wish to receive such SMSs from us,
          please notify us at -{" "}
          <a href="mailto:contact@razorpay.com">contact@cajobportal.com</a>{" "}
          to stop receiving SMSs from us. CAJobPortal.com assures that your
          Personal Information will not be made public or sold to any third
          party.
          <br />
          <br />
          The User shall have an option to erase any information provided by the
          User including Personal Information. If a User opts for the said
          option of erasure, CAJobPortal.com shall delete all stored information
          of the User from its servers.
          <br />
          <br />
          We also store customer information of customers making payments
          through CAJObPortal.com. However, only when customer chooses to share
          the information on the businesses powered with CAJobPortal.com
          applications we share the information to respective businesses.
          However, CAJobPortal.com is not liable in any way for any misuse of
          this information by the business or people related to the businesses
          to whom the information is shared by the customer.
          <br />
          <br />
          <b>Feedback</b>
          <br />
          <br />
          If you contact us to provide feedback, register a complaint, or ask a
          question, we will record any Personal Information and other content
          that you provide in your communication so that we can effectively
          respond to your communication.
          <br />
          <br />
          <b>Activity</b>
          <br />
          <br />
          We record information relating to your use of CAJObPortal.com, such as
          the searches you undertake, the pages you view, your browser type, IP
          address, location, requested URL, referring URL, and timestamp
          information. We use this type of information to administer
          CAJobPortal.com and provide the highest possible level of security and
          service to you. We also use this information in the aggregate to
          perform statistical analyses of User behavior and characteristics in
          order to measure interest in and use of the various areas of
          CAJObPortal.com. However, you cannot be identified from this aggregate
          information.
          <br />
          <br />
          We own all the intellectual property rights associated with the
          Website and its contents. No right, title or interest in any
          downloaded material is transferred to you as a result of any such
          downloading or copying. The Website is protected by copyright as a
          collective work and/ or compilation (meaning the collection,
          arrangement, and assembly) of all the content on this Website,
          pursuant to applicable law.
          <br />
          <br />
          Our logos, product and service marks and/ or names, trademarks,
          copyrights and other intellectual property, whether registered or not
          ("
          <b>OUR IP</b>") are exclusively owned by us. Without our prior written
          permission, you agree to not display and/ or use Our IP in any manner.
          Nothing contained in this Website or the content, should be construed
          as granting, in any way to the User, any license or right or interest
          whatsoever, in and/ or to Our IP, without our express written
          permission.
          <br />
          <br />
          <b>Cookies</b>
          <br />
          <br />
          We send cookies to your computer in order to uniquely identify your
          browser and improve the quality of our service. The term "cookies"
          refers to small pieces of information that a website sends to your
          computer's hard drive while you are viewing the site. We may use both
          session cookies (which expire once you close your browser) and
          persistent cookies (which stay on your computer until you delete
          them). Persistent cookies can be removed by following your browser
          help file directions. If you choose to disable cookies, some areas of
          CAJobPortal.com may not work properly or at all. CAJobPortal.com uses
          third party tools, who may collect anonymous information about your
          visits to CAJobPortal.com using cookies, and interaction with
          CAJobPortal.com products and services. Such third parties may also use
          information about your visits to CAJobPortal.comproducts and services
          and other web sites to target advertisements for CAJobPortal.com’s
          products and services. No Personal Information is collected or used in
          this process. These third parties do not know or have access to the
          name, phone number, address, email address, or any Personal
          Information about CAJobPortal.com’s Users. CAJobPortal.com. Users can
          opt-out of sharing this information with third parties by deactivating
          cookies, the process of which varies from browser to browser. Please
          refer to the help file of your browser to understand the process of
          deactivating cookies on your browser.
          <br />
          <br />
          <b>Enforcement</b>
          <br />
          <br />
          We may use the information we collect in connection with your use of
          CAJobPortal.com (including your Personal Information) in order to
          investigate, enforce, and apply our terms and conditions and Privacy
          Policy.
          <br />
          <br />
          <b>Transfer of information</b>
          <br />
          <br />
          We do not share your Personal Information with any third party apart
          from financial institutions such as banks, RBI or other regulatory
          agencies (as may be required) and to provide you with services that we
          offer through CAJobPortal.com, conduct quality assurance testing,
          facilitate creation of accounts, provide technical and customer
          support, or provide specific services, such as synchronization of your
          contacts with other software applications, in accordance with your
          instructions. These third parties are required not to use your
          Personal Information other than to provide the services requested by
          you.
          <br />
          <br />
          We may share your Personal Information with our parent company,
          subsidiaries, joint ventures, or other companies under a common
          control (collectively, the " <b>AFFILIATES</b>") that we may have now
          or in the future, in which case we will require them to honor this
          Privacy Policy. If another company acquires our company or our assets,
          that company will possess your Personal Information, and will assume
          the rights and obligations with respect to that information as
          described in this Privacy Policy. We may disclose your Personal
          Information to third parties in a good faith belief that such
          disclosure is reasonably necessary to (a) take action regarding
          suspected illegal activities; (b) enforce or apply our terms and
          conditions and Privacy Policy; (c) comply with legal process, such as
          a search warrant, subpoena, statute, or court order; or (d) protect
          our rights, reputation, and property, or that of our Users,
          Affiliates, or the public. Please note that we are not required to
          question or contest the validity of any search warrant, subpoena or
          other similar governmental request that we receive.
          <br />
          <br />
          We may disclose information in the aggregate to third parties relating
          to User behavior in connection with actual or prospective business
          relationship with those third parties, such as advertisers and content
          distributors. For example, we may disclose the number of Users that
          have been exposed to, or clicked on, advertising banners.
          <br />
          <br />
          <b>Links</b>
          <br />
          <br />
          References on this Website to any names, marks, products or services
          of third parties or hyperlinks to third party websites or information
          are provided solely for your convenience and do not in any way
          constitute or imply our endorsement, sponsorship or recommendation of
          the third party, information, product or service. Except as set forth
          herein, we do not share your Personal Information with those third
          parties, and are not responsible for their privacy practices. We
          suggest you read the privacy policies on all such third party
          websites.
          <br />
          <br />
          <b>User access of Personal Information</b>
          <br />
          <br />
          As a registered CAJobPortal.com User, you can modify some of your
          Personal Information and your privacy preferences by accessing the
          "Account" section of this Website.
          <br />
          <br />
          <b>Security</b>
          <br />
          <br />
          Your account is password protected. We use industry standard measures
          to protect the Personal Information that is stored in our database. We
          limit the access to your Personal Information to those employees and
          contractors who need access to perform their job function, such as our
          customer service personnel. If you have any questions about the
          security on CAJObPortal.com, please contact us at{" "}
          <a href="mailto:security@razorpay.com">
            security@CAJObPortal.com.com
          </a>
          <br />
          <br />
          You hereby acknowledge that CAJobPortal.com is not responsible for any
          intercepted information sent via the internet, and you hereby release
          us from any and all claims arising out of or related to the use of
          intercepted information in any unauthorized manner.
          <br />
          <br />
          <b>Terms and modifications to this Privacy Policy</b>
          <br />
          <br />
          Our Privacy Policy is subject to change at any time without notice. To
          make sure you are aware of any changes, please review this policy
          periodically. These changes will be effective immediately on the Users
          of CAJobPortal.com. Please note that at all times you are responsible
          for updating your Personal Information, including to provide us with
          your most current e-mail address.
          <br />
          <br />
          If you do not wish to permit changes in our use of your Personal
          Information, you must notify us promptly that you wish to deactivate
          your account with us. Continued use of CAJobPortal.com after any
          change/ amendment to this Privacy Policy shall indicate your
          acknowledgement of such changes and agreement to be bound by the terms
          and conditions of such changes.
          <br />
          <br />
          <b>Applicable law</b>
          <br />
          <br />
          Your use of this Website will be governed by and construed in
          accordance with the laws of India. The Users agree that any legal
          action or proceedings arising out of your use may be brought
          exclusively in the competent courts/ tribunals having jurisdiction in
          Kolkata in India and irrevocably submit themselves to the jurisdiction
          of such courts/ tribunals.
          <br />
          <br />
          <h2>
            <b>Refund Policy</b>
          </h2>
          There could be situations when customers request a refund of the
          payments made for the products or services purchased or availed on
          your website or app.
          <br />
          <br />
          Depending on the time that you wish the refunds to be processed for
          your customers, you can opt either for:
          <br />
          <br />
          <b>Normal Refund</b> where it takes 5-7 working days for the refunds
          to reach your customers.
          <br />
          <br />
          <b>Instant Refunds</b> where the customers receive their amount almost
          immediately. By issuing instant refunds to your customers, you can
          provide a better user experience for them. This also helps in
          improving their reliability and trust in your business.
          <br />
          <br />
          When you make a normal refund request to CAJobPortal, the information
          is conveyed to banking partners or other related stakeholders. Each of
          them has its own mechanism to file the refund request, map it to their
          respective payments that were originally made and then take it
          forward. On completion of this process, the refund is sent back to the
          customer's bank account or card balance.
          <br />
          <br />
          <b>Product Pricing </b>
          <br />
          <br />
          Job Posting : INR 5,000 for 5 Job Postings
          <br />
          <br />
          Please note that prices are subject to change without notice and can
          be customised as per client requirements
          <br />
          <br />
          <b>Terms & Conditions</b>
          <br />
          <br />
          <h5>
            <b>1. Introduction and Terms of Use</b>
          </h5>
          <br />
          These Terms of Use for CAJobPortal.com Services contained hereinafter
          ("
          <b>TERMS OF USE</b>") shall be in addition to the Terms & Conditions
          available on the website www.CAJobPortal.com.com and any other linked
          pages, products, software(s), API keys, features, content or
          application services (including but without limitation to any mobile
          application services) in connection therewith, offered from time to
          time by CAJobPortal.com Software Private Limited ("
          <b>CAJOBPORTAL.COM</b>" or "<b>WE</b>" or "<b>OUR</b>" or "<b>US</b>")
          (collectively, "<b>WEBSITE</b>").
          <br />
          <br />
          Any person availing the Services of CAJobPortal.com ("<b>SERVICES</b>
          ") shall be presumed to have read these Terms of Use (which includes
          the Privacy Policy, separately provided on the Website) and
          unconditionally accepted the terms and conditions set out herein.
          These Terms of Use constitute a binding and enforceable agreement
          between the User and CAJobPortal.com. These Terms of Use do not alter
          in any way the terms or conditions of any other written agreement you
          may have with CAJobPortal.com for other services.
          <br />
          <br />
          Please read the terms set out hereunder carefully before agreeing to
          the same. If you do not agree to these Terms of Use (including any
          referenced policies or guidelines), please immediately terminate your
          use of the Website. You can accept the Terms of Use by:
          <br />
          <br />
          Clicking to accept or agree to the Terms of Use, where this option is
          made available to you by CAJobPortal.com in the User interface for any
          particular Service; or
          <br />
          <br />
          Accessing, testing or actually using the Services. In this case, you
          understand and agree that CAJobPortal.com will treat your use of the
          Services as acceptance of the Terms of Use from that point onwards.
          <br />
          <br />
          For the purpose of these Terms of Use, wherever the context so
          requires, the term "<b>USER</b>" shall mean and include any natural or
          legal person who has agreed to these Terms of Use on behalf of itself
          or any other legal entity.
          <br />
          <br />
          It is clarified that the Privacy Policy (that is provided separately),
          form an integral part of these Terms of Use and should be read
          contemporaneously with the Terms of Use. Illegality or
          unenforceability of one or more provisions of these Terms of Use shall
          not affect the legality and enforceability of the other terms of the
          Terms of Use. For avoidance of doubt, if any of the provisions becomes
          void or unenforceable, the rest of the provisions of these Terms of
          Use shall be binding upon the User.
          <br />
          <br />
          The Terms of Use may be revised or altered by us at our sole
          discretion at any time without any prior intimation to the User. The
          latest Terms of Use will be posted here. Any such changes by
          CAJobPortal.com will be effective immediately. By continuing to use
          this Website or to access the Services / usage of our Services after
          changes are made, you agree to be bound by the revised/ amended Terms
          of Use and such amendments shall supersede all other terms of use
          previously accepted by the User. You are solely responsible for
          understanding and complying with all applicable laws of your specific
          jurisdiction that may be applicable to you in connection with your
          business and use of our Services.
          <br />
          <br />
          <h5>
            <b>2. PROVISION OF THE SERVICES BEING OFFERED BY CAJOBPORTAL.COM</b>
          </h5>
          <br />
          Subject to these Terms of Use, CAJobPortal.com offers the Services
          related to Job Posting
          <br />
          <br />
          CAJobPortal.com is constantly evolving in order to provide the best
          possible experience and information to its Users. You acknowledge and
          agree that the form and nature of the Services which CAJobPortal.com
          provides may change from time to time without any prior notice to you.
          <br />
          <br />
          As part of this continuing process, you acknowledge and agree that
          CAJobPortal.com may stop (temporarily or permanently) providing the
          Services (or any features within the Services) to you or to Users
          generally at CAJobPortal.com's sole discretion, without any prior
          notice. You may stop using the Services at any point of time. You do
          not need to specifically inform CAJobPortal.com when you stop using
          the Services.
          <br />
          <br />
          You acknowledge and agree that if CAJobPortal.com disables access to
          your account, you may be prevented from accessing the Services, your
          account details or any files or other content which are contained in
          your account, and CAJobPortal.com shall intimate you regarding the
          same.
          <br />
          <br />
          CAJobPortal.com reserves the right to delete your User information
          stored in your account including but not limited to any or all
          personal information or any sensitive personal data or information
          ("SPDI") stored in your User account. Alternately, a User may notify
          us if they do not wish that we retain or use the personal information
          or SPDI by contacting the Grievance Officer (as provided below).
          However, in such a case, we may not be able to provide you some or all
          of our Services.
          <br />
          <br />
          You acknowledge and agree that while CAJobPortal.com may not have
          currently set a fixed upper limit on the number of transmissions you
          may send or receive through the Services, such fixed upper limits may
          be set by CAJobPortal.com at any time, solely at CAJobPortal.com's
          discretion.
          <br />
          <br />
          By using our Services, you agree that CAJobPortal.com disclaims any
          liability or authenticity of any information that may have become
          outdated since the last time that particular piece of information was
          updated. CAJobPortal.com reserves the right to make changes and
          corrections to any part of the content of this Website at any time
          without any prior notice to you. Unless stated otherwise, all pictures
          and information contained on this Website are believed to be in the
          public domain as either promotional materials, publicity photos,
          photoshoot rejects or press media stock. Please contact the Grievance
          Officer by an e-mail if you are the copyright owner of any content on
          this Website and you think the use of the above material violates the
          terms of the applicable Copyright law in any manner. In your request,
          please indicate the exact URL of the webpage to enable us to locate
          the same. We will endeavour to address your concerns and take
          necessary steps, if required. Please note that all images displayed in
          the Website have been digitised by CAJobPortal.com. No other party is
          authorised to reproduce or republish these digital versions in any
          format whatsoever without the prior written permission of
          CAJobPortal.com.
          <br />
          <br />
          <h5>
            <b>
              3. FOR THE PURPOSE OF THIS TERMS OF USE, THE BELOW DEFINTIONS
              SHALL BE CONSTRUED TO MEAN AND INCLUDE AS STATED BELOW –
            </b>
          </h5>
          <br />
          (a) "<b>API</b>" means application programming interface which refers
          to electronic communication protocols used by the software of the
          various entities / intermediaries in a payment system established to
          initiate, implement and effect electronic payments;
          <br />
          (b) "<b>BANK ACCOUNT</b>" means a banking account maintained by the
          User.
          <br />
          (c) "<b>NPCI</b>" means the National Payments Corporation of India
          constituted pursuant to inter alia the provisions of the Payment and
          Settlement Systems Act, 2007;
          <br />
          (d) "<b>PERSONAL DATA</b>" means the 'personal information' and
          'sensitive personal data or information' as defined under the
          Information Technology Act, 2000 and the rules and regulations
          thereunder;
          <br />
          (e) "<b>SPONSOR BANKS</b>" mean the banks / entities with whom
          CAJobPortal.com has partnered to provide payment solutions to the User
          by establishing software protocols and integrations via API
          <br />
          <br />
          <h5>
            <b>4. AUTHORISATIONS BY THE USER</b>
          </h5>
          <br />
          (a) The User has authorised CAJobPortal.com to generate LDAP, User &
          Secret ID and subscribe to the API's on Sponsor Banks web portal or
          software platform on behalf of the User to facilitate the Services.
          (b) The User has further authorised CAJobPortal.com to service the
          User's current account on their behalf with regards to queries and
          complaints raised by the User and/or third party for facilitation of
          the Services including authorisation for raising of queries with the
          banks on their behalf.
          <br />
          <br />
          <h5>
            <b>5. INDEMNIFICATION</b>
          </h5>
          <br />
          You shall indemnify and hold CAJobPortal.com, its directors, officers,
          employees, agents representatives, vendors and any related parties
          whatsoever, harmless from and against any and all losses, damages,
          costs or expenses (including reasonable attorney's fees and any other
          pecuniary liability or any other obligations of any kind whatsoever)
          on account of claims, judgments, awards, decrees, settlements, fines,
          penalties arising from, including but not limited to the following:
          <br />
          <br />
          (a) In respect of communications and customer information which have
          been furnished by Client or appears to have been furnished by or on
          behalf of Client;
          <br />
          (b) In respect of the said arrangement for API integration for
          providing the Services;
          <br />
          (c) In respect of any debit to the current account of the User
          maintained with CAJobPortal.com for the purpose of payments to
          customers/ vendors/ employee of User based on the instructions sent by
          User;
          <br />
          (d) In respect of breach of security measures/ standards/ regulations/
          requirements/ guidelines which would be applicable for API Services
          directly attributable to breach by User;
          <br />
          (e) Any wrongful, incorrect, dishonest, criminal, fraudulent or
          negligent work, default, failure, misrepresentation, misfeasance, bad
          faith, and/or misconduct (including perpetration of, or aiding and
          abetting fraud), any act or omission directly attributable to default
          of or by User; and
          <br />
          (f) Any legal actions brought against CAJobPortal.com arising out of
          or directly attributable to User's management of API integration with
          the Sponsor Banks and/or the customer, including arising from any
          system, dashboard, or platform created by the User based on the API
          integration for its customer.
        </div>
      </div>
    </div>
   </>}
  </>
   
  );
};

export default Privacy;
