import React, { useEffect, useState } from "react";
import Sidebar from "../../Common/Sidebar";
import { useDispatch } from "react-redux";
import { GetMyPostedJobs, getAllJobs } from "../../../Redux/Slices/JobPost";
import { GetUserSubscriptions } from "../../../Redux/Slices/Package";
import EmployerSubscriptions from "./EmployerSubscriptions";
import NoSubscriptionAlert from "./NoSubscriptionAlert";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../Helper/FromatTime";

const EmployerDashboard = () => {
  const [allJobs, setAllJobs] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [salesHistory, setSalesHistory] = useState(null);

  const salesHistoryHandler = async () => {
    let data = await GetUserSubscriptions();
    setSalesHistory(data?.subscriptions);
  };

  //----------  Dasboard Count Number --------------

  const postedJobs = allJobs?.docs?.filter((item) => item)?.length;

  const approvedJobs = allJobs?.docs?.filter(
    (item) => item?.admin_approval === "approved"
  );

  const activeJobs = allJobs?.docs?.filter(
    (item) => item?.admin_approval === "approved" && item?.is_active === true
  )?.length;

  const rejectedJobs = allJobs?.docs?.filter(
    (item) => item?.admin_approval === "rejected" || item?.is_active === false
  )?.length;

  // ------------ Views -----------
  const getTotalViews = (jobsData) => {
    let totalViews = 0;

    jobsData?.forEach((job) => {
      totalViews += job?.views || 0;
    });

    return totalViews;
  };
  //---------- Total Applicants ---------
  const getTotalUsers = (jobsData) => {
    const uniqueUsers = new Set();

    jobsData?.forEach((job) => {
      job.applications?.forEach((application) => {
        uniqueUsers?.add(application?._id);
      });
    });

    return uniqueUsers?.size;
  };

  /// Get the total number of views on post
  const totalViews = getTotalViews(allJobs?.docs);

  const myjobhnadler = async () => {
    let data = await GetMyPostedJobs();
    setAllJobs(data);
  };

  useEffect(() => {
    myjobhnadler();
    dispatch(getAllJobs());
    salesHistoryHandler();
    document.title = "Dashboard | CAJobPortal";
  }, []);

  return (
    <div className="m-5">
      <div className="row ">
        {/* ------------ SideBar ------------- */}
        <Sidebar />
        <div className="col-md-10">
          <h1>Home</h1>

          {/* Alert */}
          <NoSubscriptionAlert salesHistory={salesHistory} />
          <br />
          {/* --------- 3 Card ------- */}
          <div className="d-flex justify-content-around ">
            <div className="p-1 border border-1 das-card2 mt-3">
              <p className="fs-6 ">Views</p>
              <center>
                <h1 className="font-size-no">{totalViews || 0}</h1>
              </center>
            </div>
            <div className="p-1 border border-1 das-card2 mt-3">
              <p className="fs-6 text-nowrap">Application</p>
              <center>
                <h1 className=" font-size-no">{getTotalUsers(allJobs?.docs) || 0}</h1>
              </center>
            </div>
            <div className="p-1 border border-1 das-card2 mt-3">
              <p className="fs-6 text-nowrap">Job Posted</p>
              <center>
                <h1 className=" font-size-no">{postedJobs || 0}</h1>
              </center>
            </div>
            <div className="p-1 border border-1 das-card2 mt-3">
              <p className="fs-6 text-nowrap">Active Jobs</p>
              <center>
                <h1 className=" font-size-no">{activeJobs || 0}</h1>
              </center>
            </div>
            <div className="p-1 border border-1 das-card2 mt-3">
              <p className="fs-6 text-nowrap">Inactive Jobs</p>
              <center>
                <h1 className=" font-size-no">{rejectedJobs || 0}</h1>
              </center>
            </div>
          </div>

          {/* --------- Pending Jobs -----------*/}
          {approvedJobs?.length > 0 && (
            <>
              {" "}
              <nav>
                <div
                  className="nav nav-tabs nav-jobs nav-fill mt-3"
                  id="nav-tab"
                  role="tablist"
                >
                  <p className={`nav-item nav-link active`}>
                    <b>Pending Jobs</b>
                  </p>
                </div>
              </nav>
              <div
                className="tab-pane fade show active"
                id="nav-approve"
                role="tabpanel"
              >
                <table
                  id="example"
                  className="table dataTable no-footer"
                  cellSpacing={0}
                  style={{ width: "100%", fontSize: "12px" }}
                  aria-describedby="example_info"
                >
                  <thead className="table-headline">
                    <tr>
                      <th className="text-nowrap">Job ID</th>
                      <th>Job Title</th>
                      <th>Posted By</th>
                      <th>Posted date</th>
                      <th>Industry</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  {/* Add rows for approved jobs here */}
                  <tbody>
                    {approvedJobs?.length !== 0 ? (
                      approvedJobs?.map((value, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{value?.title}</td>
                          <td>{value?.created_by?.name || "N/A"}</td>
                          <td>{formatDate(value?.posted_date)}</td>
                          <td>{value?.industry?.name || "N/A"}</td>
                          <td className="text-nowrap">
                            <button
                              onClick={() =>
                                navigate(`/employer/reviewJob/${value?._id}`)
                              }
                              className="unblock-btn"
                            >
                              View Job Detail
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <td valign="top" colspan="7" className="dataTables_empty p-2">
                        {" "}
                        <center>No Data Available In Table</center>
                      </td>
                    )}
                  </tbody>
                </table>
              </div>
            </>
          )}

          {/* Subscription */}
          {salesHistory &&  <EmployerSubscriptions salesHistory={salesHistory} />}
          {/* <EmployerSubscriptions/> */}
        </div>
      </div>
    </div>
  );
};

export default EmployerDashboard;
