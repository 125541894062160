import React from "react";
import { formatDate } from "../../../Helper/FromatTime.js";

const EmployerSubscriptions = ({ salesHistory }) => {
  const userId = localStorage.getItem("id");

  function formatTimestamp(utcTimestamp) {
    const dateObject = new Date(utcTimestamp);
    return dateObject
      .toISOString()
      .replace(/T/, "  ")
      .replace(/\.\d+Z$/, "");
  }

  const isExpired = (expires_in) => new Date(expires_in) < new Date();

  return (
    <div>
      {salesHistory?.length > 0 ? (
        <>
          {/* -------- Balance ------- */}
          <div className="mt-3 p-3 border-bottom">
            <h2>Balance</h2>
          </div>
          <div className="purchased-card-container">
            {salesHistory
              ?.filter((item) => item?.is_active)
              .map((value) => (
                <div
                  className="p-1 border border-1 das-card2 mt-3"
                  key={value?._id}
                >
                  <h5 className="text-info mt-3 my-3 mx-2">
                    {value?.package?.name} Balance
                  </h5>
                  <div className="purchase-details">
                    <p>
                      <b>Purchased</b>:{" "}
                      {value?.package?.name === "Job Posting" ||
                      value?.package?.name === "Bulk Job Posting"
                        ? value?.package?.no_job_posting
                        : value?.package?.no_cv_search}{" "}
                      , Used:{" "}
                      {value?.package?.name === "Job Posting" ||
                      value?.package?.name === "Bulk Job Posting"
                        ? value?.package?.no_job_posting -
                          value?.avail_jobposting
                        : value?.package?.no_cv_search -
                          value?.avail_cvsearch}{" "}
                      , Available:{" "}
                      {value?.package?.name === "Job Posting" ||
                      value?.package?.name === "Bulk Job Posting"
                        ? value?.avail_jobposting
                        : value?.avail_cvsearch}
                    </p>

                    <p>
                      <b>Valid till</b>: {formatDate(value?.expires_in)} (
                      {value?.package?.validity_day} Days)
                      <span
                        style={{
                          color: isExpired(value?.expires_in) ? "red" : "green",
                          fontWeight: "500",
                          marginLeft: "10px",
                        }}
                      >
                        {isExpired(value?.expires_in) ? "Expired" : "Active"}
                      </span>
                    </p>
                  </div>
                </div>
              ))}
          </div>

          {/* -------- Purchased Packages ------- */}
          {/* <div className="mt-3 p-3 border-bottom">
            <h2>Packages</h2>
          </div>
          <div className="purchased-card-container">
            {salesHistory
              ?.filter((item) => item?.employer?._id === userId)
              .map((value) => (
                <div
                  className="p-1 border border-1 das-card2 mt-3"
                  key={value?._id}
                >
                  <h5 className="text-primary mt-3 my-3 mx-2">
                    Purchase Details
                  </h5>
                  <div className="purchase-details">
                    <p>
                      <b>Purchase on</b>: {formatTimestamp(value?.created_at)}
                    </p>
                    <p>
                      <b>Valid till</b>: {formatDate(value?.expires_in)}
                      <span
                        style={{
                          color: isExpired(value?.expires_in) ? "red" : "green",
                          fontWeight: "bold",
                          marginLeft: "10px",
                        }}
                      >
                        {isExpired(value?.expires_in) ? "Expired" : "Active"}
                      </span>
                    </p>
                    <p>
                      <b>Package</b>: {value?.package?.name}
                    </p>
                  </div>
                </div>
              ))}
          </div> */}
        </>
      ) : null}
    </div>
  );
};

export default EmployerSubscriptions;
