import React, { useEffect } from "react";
import "./PageNotFound.css";
import { NavLink } from "react-router-dom";
const PageNotFound = () => {
  useEffect(() => {
    document.title = "Page Not Found";
  }, []);
  return (
    <>
      <div id="notfound">
        <div className="notfound">
          <div className="notfound-404">
            <h1>Oops!</h1>
          </div>
          <h5>404 - The Page can't be found</h5>
          <br />
          <NavLink to={"/"}>Go TO Homepage</NavLink>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
