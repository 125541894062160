import React, { useEffect, useState } from "react";
import Sidebar from "../../Common/Sidebar";
import { AiOutlinePlus } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { GetMyPostedJobs, JobStatus } from "../../../Redux/Slices/JobPost";
import NoSubscriptionAlert from "./NoSubscriptionAlert";
import { formatDate } from "../../../Helper/FromatTime";
import { GetUserSubscriptions } from "../../../Redux/Slices/Package";

const PostedJobs = () => {
  const [activeTab, setActiveTab] = useState("approved");
  const [allJobs, setAllJobs] = useState(null);
  const navigate = useNavigate();

  const approvedJobs = allJobs?.docs?.filter(
    (item) => item?.admin_approval === "approved"
  );

  const rejectedJobs = allJobs?.docs?.filter(
    (item) => item?.admin_approval === "rejected"
  );

  const pendingJobs = allJobs?.docs?.filter(
    (item) => item?.admin_approval === "pending"
  );

  const handleTabChange = async (tab) => {
    setActiveTab(tab);
  };

  // Block and Unblock
  const userStatushandler = async (id, data) => {
    const userClickedOK = await window.confirm(
      "Are you sure you want to proceed?"
    );

    if (userClickedOK) {
      let action = { action: data };
      await JobStatus(id, action);
      myjobhnadler();
    }
  };

  const myjobhnadler = async () => {
    let data = await GetMyPostedJobs();
    setAllJobs(data);
  };

  const [salesHistory, setSalesHistory] = useState(null);
  const salesHistoryHandler = async () => {
    let data = await GetUserSubscriptions();
    setSalesHistory(data?.subscriptions);
  };

  useEffect(() => {
    myjobhnadler();
    salesHistoryHandler()
    document.title = "Posted Job | CAJobPortal";
  }, []);

  return (
    <div className="m-5">
      <div className="row ">
        {/* ------------ SideBar ------------- */}
        <Sidebar />
        <div className="col-md-10">
          <div className="">
            <div classname="text-center">
              <button
                type="button"
                style={{ padding: "3px 30px" }}
                className="job_btn mb-3"
                onClick={() => navigate("/employer/postjob")}
              >
                <AiOutlinePlus style={{ fontSize: "20px" }} />
                &nbsp;&nbsp; Post Job
              </button>
            </div>
            {/* Alert */}
            <NoSubscriptionAlert salesHistory={salesHistory}  />

            <div className="d-flex justify-content-between mt-3">
              {/* --- Search Input --- */}

              
            </div>
            <div className="main-panel">
              <div
                className="container p-1 pt-0"
                style={{ backgroundColor: "white" }}
              >
                <nav>
                  <div
                    className="nav nav-tabs nav-jobs nav-fill"
                    id="nav-tab"
                    role="tablist"
                  >
                    <Link
                      className={`nav-item nav-link ${
                        activeTab === "approved" ? "active" : ""
                      }`}
                      onClick={() => handleTabChange("approved")}
                    >
                      <b>Approved</b>
                    </Link>
                    <Link
                      className={`nav-item nav-link ${
                        activeTab === "rejected" ? "active" : ""
                      }`}
                      onClick={() => handleTabChange("rejected")}
                    >
                      <b>Rejected</b>
                    </Link>
                    <Link
                      className={`nav-item nav-link ${
                        activeTab === "pending" ? "active" : ""
                      }`}
                      onClick={() => handleTabChange("pending")}
                    >
                      <b>Pending</b>
                    </Link>
                  </div>
                </nav>
                <div
                  className="tab-content py-3 px-3 px-sm-0"
                  id="nav-tabContent"
                >
                  {/* Use activeTab state to conditionally render content */}
                  {activeTab === "approved" && (
                    <div
                      className="tab-pane fade show active"
                      id="nav-approve"
                      role="tabpanel"
                    >
                      <table
                        id="example"
                        className="table dataTable no-footer"
                        cellSpacing={0}
                        style={{ width: "100%", fontSize: "12px" }}
                        aria-describedby="example_info"
                      >
                        <thead className="table-headline">
                          <tr>
                            <th className="text-nowrap">Job ID</th>
                            <th>Job Title</th>
                            <th>Posted By</th>
                            <th>Posted date</th>
                            <th>Industry</th>
                            <th>App.Candidates</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        {/* Add rows for approved jobs here */}
                        <tbody>
                          {approvedJobs?.length !== 0 ? (
                            approvedJobs?.map((value, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{value?.title}</td>
                                <td>{value?.created_by?.name || "N/A"}</td>
                                <td>{formatDate(value?.posted_date)}</td>
                                <td>{value?.industry?.name || "N/A"}</td>
                                <td>{value?.applications?.length}</td>
                                <td className="text-nowrap">
                                  <button
                                    onClick={() =>
                                      navigate(
                                        `/employer/reviewJob/${value?._id}`
                                      )
                                    }
                                    className="unblock-btn"
                                  >
                                    View Job Detail
                                  </button>
                                  <button
                                    onClick={() =>
                                      navigate(
                                        `/employer/job-applicants/${value?._id}`
                                      )
                                    }
                                    className="view-app-btn"
                                  >
                                    View Applicants
                                  </button>
                                  {activeTab === "approved" && (
                                    <button
                                      onClick={() =>
                                        navigate(
                                          `/employer/editjob/${value?._id}`
                                        )
                                      }
                                      className="edit-btn"
                                    >
                                      Edit
                                    </button>
                                  )}
                                  {activeTab === "approved" &&
                                    value?.is_active === true && (
                                      <button
                                        onClick={() =>
                                          userStatushandler(value?._id, "false")
                                        }
                                        className="block-btn"
                                      >
                                        Deactivate
                                      </button>
                                    )}
                                  {activeTab === "approved" &&
                                    value?.is_active === false && (
                                      <button
                                        onClick={() =>
                                          userStatushandler(value?._id, "true")
                                        }
                                        className="approve-btn"
                                      >
                                        Activate
                                      </button>
                                    )}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <td
                              valign="top"
                              colspan="7"
                              className="dataTables_empty p-2"
                            >
                              {" "}
                              <center>No Data Available In Table</center>
                            </td>
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {activeTab === "rejected" && (
                    <div
                      className="tab-pane fade show active"
                      id="nav-approve"
                      role="tabpanel"
                    >
                      <table
                        id="example"
                        className="table dataTable no-footer"
                        cellSpacing={0}
                        style={{ width: "100%", fontSize: "12px" }}
                        aria-describedby="example_info"
                      >
                        <thead thead className="table-headline">
                          <tr>
                            <th>No</th>
                            <th>Job Title</th>
                            <th>Posted By</th>
                            <th>Posted date</th>
                            <th>Industry</th>
                            <th>Reason</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        {/* rows for rejected jobs here */}
                        <tbody>
                          {rejectedJobs?.length !== 0 ? (
                            rejectedJobs?.map((value, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{value?.title}</td>
                                <td>{value?.created_by?.name || "N/A"}</td>
                                <td>{formatDate(value?.posted_date)}</td>
                                <td>{value?.industry?.name || "N/A"}</td>
                                <td>{value?.remark || "N/A"}</td>
                                <td>
                                  <button
                                    onClick={() =>
                                      navigate(
                                        `/employer/reviewJob/${value?._id}`
                                      )
                                    }
                                    className="unblock-btn"
                                  >
                                    View Job Detail
                                  </button>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <td
                              valign="top"
                              colspan="7"
                              className="dataTables_empty p-2"
                            >
                              {" "}
                              <center>No Data Available In Table</center>
                            </td>
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {activeTab === "pending" && (
                    <div
                      className="tab-pane fade show active"
                      id="nav-approve"
                      role="tabpanel"
                    >
                      <table
                        id="example"
                        className="table dataTable no-footer"
                        cellSpacing={0}
                        style={{ width: "100%", fontSize: "12px" }}
                        aria-describedby="example_info"
                      >
                        <thead thead className="table-headline">
                          <tr>
                            <th>Job ID</th>
                            <th>Job Title</th>
                            <th>Posted By</th>
                            <th>Posted date</th>
                            <th>Industry</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        {/* Rows for pending jobs here */}
                        <tbody>
                          {pendingJobs?.length !== 0 ? (
                            pendingJobs?.map((value, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{value?.title}</td>
                                <td>{value?.created_by?.name || "N/A"}</td>
                                <td>{formatDate(value?.posted_date)}</td>
                                <td>{value?.industry?.name || "N/A"}</td>
                                <td className="text-nowrap">
                                  <button
                                    onClick={() =>
                                      navigate(
                                        `/employer/reviewJob/${value?._id}`
                                      )
                                    }
                                    className="unblock-btn"
                                  >
                                    View Job Detail
                                  </button>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <td
                              valign="top"
                              colspan="6"
                              className="dataTables_empty p-2"
                            >
                              {" "}
                              <center>No Data Available In Table</center>
                            </td>
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostedJobs;
